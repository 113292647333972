import React, { useEffect, useRef, useState } from 'react';
import { Button, makeStyles, FormControl, FormLabel, Typography, Input, Grid, RadioGroup, FormControlLabel, Radio, Select, MenuItem, InputLabel } from '@material-ui/core';
import Dialog from '../ui/Dialog';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay.component';
import { approveStandardScheme, standardSchemeAction } from '../../services/customer.service';
import CreateIcon from '@material-ui/icons/Create';
import { upload }  from '../../services/storage.service';
import moment from 'moment'
import { toNumber, toLower, find, filter } from 'lodash';
import { getProductList } from '../../services/product.service';

const PreApprovalScheme = ({ data }) => {
  const ACTION_APPROVE = 'approve';
  const { program_id, patient_name, scheme, scheme_id, patient_id, product_id, product_sku_id, indication, product, product_sku, vial_per_cycle } = data;
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmation, setConfirmation] = useState(false);
  const [confirmApproveDisapprove, setConfirmApproveDisapprove] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState('Successfully assigned standard scheme!');
  const [isLoading, setLoading] = useState(false);
  const [action, setAction] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [standardScheme, setStandardScheme] = useState(null);
  const [vialPerCycle, setVialPerCycle] = useState(1);
  const [standardSchemesAvailable, setStandardSchemesAvailable] = useState(null);

  useEffect(() => {
    const getProducts = async() => {
      const productCatalog = await getProductList();

      if (productCatalog && productCatalog.length) {
        const product = find(productCatalog, { brand_id: product_id });

        setProductDetails(productDetails);

        const { brand_metadata } = product;
        const standardSchemes = filter(brand_metadata.schemes, (scheme) => {
          if (scheme.indication) {
            return scheme.indication === indication && scheme.standard
          }
          return scheme.standard;
        });

        setStandardSchemesAvailable(standardSchemes);
        setStandardScheme(standardSchemes[0].scheme_id);
      }
    }

    getProducts();
  }, []);

  const useStyles = makeStyles(() => ({
    field: {
      marginBottom: 20,
    },
    formControl: {
      width: '100%'
    }
  }));

  const classes = useStyles();
  
  const handleClickAction = (status) => {
    setOpenModal(true);
  }

  const handleCloseSubmit = () => {
    setConfirmation(false);
    setErrorMessage(null);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const handleClose = () => {
    setOpenModal(false);
    setConfirmation(false);
    setErrorMessage(null);

    if (openConfirmation) {
      window.location.reload();
    }
  }

  const blobToFile = (imageBlob) => {
    return new File([imageBlob], imageBlob.name, {
        lastModified: moment().unix(),
        type: imageBlob.type,
    });
  }

  const onSubmit = async () => {
    try {
      setLoading(true);

      await standardSchemeAction({ program_id, customer_id: patient_id, payload: {
        scheme_id: standardScheme,
        vial_per_cyle: vialPerCycle
      }, action: ACTION_APPROVE});
  
      setOpenModal(false);
      setConfirmation(true);
    } catch(err) {
      setLoading(false);
      alert(err.message);
    } finally {
      setLoading(false);
    }
  }

  const onFieldInput = (e) => {
    const { value } = e.target;

    setStandardScheme(value);
  }

  const formContent = (
    <>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
          <Typography>
            Product
          </Typography>
        </FormLabel>
        <FormLabel className={classes.field}>
          <Typography>
            <b>{product}</b>
          </Typography>
        </FormLabel>
      </FormControl>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
          <Typography>
            Product SKU
          </Typography>
        </FormLabel>
        <FormLabel className={classes.field}>
          <Typography>
            <b>{product_sku}</b>
          </Typography>
        </FormLabel>
      </FormControl>
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
          <Typography>
            Indication
          </Typography>
        </FormLabel>
        <FormLabel className={classes.field}>
          <Typography>
            <b>{indication}</b>
          </Typography>
        </FormLabel>
      </FormControl>
      {
        product_id === "adcetris" ? 
          <FormControl className={classes.formControl}>
          <FormLabel className={classes.field}>
          <InputLabel>Vial per cycle</InputLabel>
          </FormLabel>
          <FormLabel className={classes.field}>
              <Select
                required={true}
                label='Number of Vial per cycle'
                name="vial_per_cycle"
                variant='outlined' 
                defaultValue={vialPerCycle}
                className={classes.field}
                onChange={(e) => {
                  const { value } = e.target;
                  setVialPerCycle(value);
                }}>
                  <MenuItem className={classes.menuItem} key="vial_per_cycle-1" value={1}>1</MenuItem>
                  <MenuItem className={classes.menuItem} key="vial_per_cycle-2" value={2}>2</MenuItem>
                  <MenuItem className={classes.menuItem} key="vial_per_cycle-3" value={3}>3</MenuItem>
                  <MenuItem className={classes.menuItem} key="vial_per_cycle-4" value={4}>4</MenuItem>
              </Select> : <></>
          </FormLabel>
        </FormControl> : <></>
      }
      <FormControl className={classes.formControl}>
        <FormLabel className={classes.field}>
        <InputLabel>Scheme</InputLabel>
        </FormLabel>
        <FormLabel className={classes.field}>
          {
            standardSchemesAvailable && standardSchemesAvailable.length ? 
            <Select
              required={true}
              label='Scheme'
              name="scheme"
              variant='outlined' 
              defaultValue={standardSchemesAvailable[0].scheme_id}
              className={classes.field}
              onChange={onFieldInput}>
                {
                  standardSchemesAvailable && standardSchemesAvailable.length ? standardSchemesAvailable.map(scheme => {
                    return (
                      <MenuItem className={classes.menuItem} key={scheme.scheme_id} value={scheme.scheme_id}>
                        {scheme.name ? `${scheme.name} - ${scheme.scheme_label}` : scheme.scheme_label }
                      </MenuItem>
                    )
                  }) : <></>
                }
            </Select> : <></>
          }
        </FormLabel>
      </FormControl>
      <Typography
          variant='caption'
          display='block'
          gutterBottom
          color='error'
      >
          { errorMessage }
      </Typography>
    </>
  );

  const formButtons = (
    <>
        { openConfirmation ? 
        <Button onClick={handleClose} variant='contained'>
            OK
        </Button>
        : 
        <>
          <Button onClick={handleClose} variant='contained'>
              Close
          </Button>
          <Button onClick={onSubmit} variant='contained' color='primary'>
            Submit
          </Button> 
        </>
        }
    </>
  );

  const confirmApproveButtons = (
    <>
        <Button onClick={() => {
          setConfirmation(false)
          setOpenModal(false)
        }} variant='contained' color='secondary'>
          Close
        </Button>
        <Button onClick={onSubmit} variant='contained' color='primary'>
          Yes
        </Button>
    </>
  );

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
     <Dialog 
      key='approve-standard-scheme'
      open={openModal} 
      title = {"Approval of Standard Scheme"}
      content = {formContent}
      children = {formButtons}
      onSubmit={handleClickAction}
      onClose={handleClose}
      />
     <Dialog 
      key='confirmation'
      open={openConfirmation}
      title = 'Confirmation'
      content = {successMessage}
      children = {formButtons}
      onClose={handleClose}
      />
      <Dialog 
      key='confirm-approve-standard-scheme'
      open={confirmApproveDisapprove}
      title = 'Confirmation'
      content = {`Are you sure to set this standard scheme?`}
      children = {confirmApproveButtons}
      onClose={() => setConfirmation(false)}
      />
      <Button variant="contained" color="primary" onClick={() => handleClickAction(ACTION_APPROVE)} style={{ marginRight: 8 }}>
        Assign
      </Button>
    </>
  )
};

export default PreApprovalScheme;